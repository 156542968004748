export const songType = new Map([
  [0, { name: '演唱歌曲', presetColor: 'pink', key: 0 }],
  [2, { name: '合唱歌曲', presetColor: 'orange', key: 2 }],
  [3, { name: '多声部练习曲', presetColor: 'green', key: 3 }],
  [4, { name: '钢琴歌曲', presetColor: 'blue', key: 4 }]
])

export const songSheetType = {
  0: { name: '简谱' },
  1: { name: '五线谱' },
  2: { name: '简线同谱' },
  3: { name: '暂无曲谱' }
}

export const audioType = ['mp3', 'wav', 'wma', 'ogg', 'aac', 'm4a']

export const excelType = ['xls', 'xlsx']

// 批量导入演唱、合唱、多声部练声曲时，右侧的提示
export const importDirDesc = {
  acc: 'acc 文件夹下歌曲文件名称为：歌曲识别码-acc.mp3',
  evXML: 'evXML 文件夹下文件名称为：歌曲识别码.evxml',
  image: 'image 文件夹下仍为文件夹，且命名为：歌曲识别码。歌曲识别码文件夹下即为对应歌曲的图片',
  musicXML: 'musicXML 文件夹下文件名称为：歌曲识别码.musicxml',
  evms: 'evms 文件夹下文件名称为：歌曲识别码.evms',
  ori: 'ori 文件夹下歌曲文件名称为：歌曲识别码-ori.mp3'
}

// 批量导入演唱、合唱、多声部练声曲时，允许上传的文件夹
export const importAcceptDir = Object.keys(importDirDesc)

// 批量导入演唱、合唱、多声部练声曲时，配置文件表中的字段与接口字段的映射
export const sheetKeysMap = {
  歌曲识别码: 'song_code',
  歌曲中文名: 'name',
  歌曲简拼: 'py',
  歌曲速度: 'speed',
  伴奏速度: 'acc_speed',
  所属教材ID: 'book_id',
  歌曲类型: 'song_type',
  谱例类型: 'sheet_type' // musicxml 是五线谱
}
