import * as XLSX from 'xlsx'

const acceptXlsx =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'

/**
 * 将 Excel 文件里的数据导出为数组
 * @param {*} file excel 文件
 * @returns data: { Sheet1: [array], Sheet2: [array], ...}
 */
function xlsxImport (file) {
  return new Promise((resolve, reject) => {
    if (!acceptXlsx.includes(file.type)) {
      reject(new Error({ message: '文件格式错误！', code: -1 }))
    }
    // 通过FileReader对象读取文件
    const fileReader = new FileReader()
    fileReader.onload = event => {
      try {
        const { result } = event.target
        // 以二进制流方式读取得到整份excel表格对象
        const { Sheets, SheetNames } = XLSX.read(result, { type: 'binary' })
        // 存储获取到的数据
        const data = {}
        // 遍历每张工作表进行读取
        SheetNames.forEach(key => {
          const sheet = Sheets[key]
          data[key] = XLSX.utils.sheet_to_json(sheet)
        })
        resolve(data)
      } catch (e) {
        console.error(e)
        reject(new Error({ message: '文件解析错误！', code: 0 }))
      }
    }
    fileReader.readAsBinaryString(file)
  })
}

export { xlsxImport, acceptXlsx }
