<template>
  <a-card title="批量导入欣赏歌曲">
    <template #extra>
      <a @click="history.go(-1)">返回</a>
    </template>
    <a-row>
      <a-col span="6">
        <a-upload
          directory
          :fileList="allFileList"
          :before-upload="beforeUpload"
          :onRemove="handleRemove"
        >
          <a-button>
            选择文件夹
          </a-button>
        </a-upload>
      </a-col>
      <a-col span="6">
        <a-button :disabled="!pageState.canReset" @click="handleReset">
          重置
        </a-button>
        <a-button
          type="primary"
          style="margin-bottom: 8px; margin-left: 8px"
          :loading="pageState.uploading"
          :disabled="allFileList.length === 0 || pageState.uploading || pageState.loading"
          @click="handleUpload"
        >
          开始上传
        </a-button>
        <a-button
          type="primary"
          style="margin-left: 8px"
          :loading="pageState.loading"
          :disabled="!pageState.finish"
          @click="handleBatch"
        >
          创建
        </a-button>
      </a-col>
      <a-col span="12">
        <a-card title="说明">
          <div style="color: red">
          注：推荐使用 {{browsers.join('/')}} 导入，其它浏览器可能导入失败！
          </div>
          <div>上传的文件夹目录结构如下：</div>
          <div class="outerLevel">
            <span>-root (上传的根文件夹，任意命名)</span>
            <div class="innerLevel">
              <span>-image (文件夹，必须命名为 image)</span>
              <div class="innerLevel">
                <span>-1112003 (文件夹，必须以歌曲识别码命名)</span>
                <div class="innerLevel">-image.jpg (图片名)</div>
                <div class="innerLevel">-...... (省略多个图片)</div>
              </div>
              <div class="innerLevel">-...... (省略多个文件夹)</div>
            </div>
            <div class="innerLevel">
              <span>-mp3 (文件夹，必须命名为 mp3)</span>
              <div class="innerLevel">
                <span>-1112003 (文件夹，必须以歌曲识别码命名)</span>
                <div class="innerLevel">
                  <span>-歌曲A (文件夹，必须以 mp3 文件名命名)</span>
                  <div class="innerLevel">-歌曲A.mp3 (mp3 文件)</div>
                  <div class="innerLevel">
                    -歌曲A.xlsx (mp3 分段配置文件，可选)
                  </div>
                </div>
                <div class="innerLevel">
                  <span>-歌曲B (文件夹，必须以 mp3 文件名命名)</span>
                  <div class="innerLevel">-歌曲B.mp3 (mp3 文件)</div>
                  <div class="innerLevel">
                    -歌曲B.xlsx (mp3 分段配置文件，可选)
                  </div>
                </div>
                <div class="innerLevel">-...... (省略多个文件夹)</div>
              </div>
              <div class="innerLevel">-...... (省略多个文件夹)</div>
            </div>
            <div class="innerLevel">
              <span>-config.xlsx (必须命名为 config.xlsx 或 config.xls)</span>
              <span style="padding-left: 8px">
          <a :href="CONFIG_URL" download>
            config 文件示例
          </a>
        </span>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </a-card>
  <a-modal
    v-if="pageState.errorVisible"
    destroyOnClose
    title="文件错误"
    :footer="null"
    :width="1000"
    :maskClosable="false"
    :visible="pageState.errorVisible"
    style="top: 32px"
    :body-style="{lineHeight: 2}"
    :onCancel="handleErrorsCancel"
  >
    <div>
      <div>
        (提示：建议根据上述结果确认不可上传的文件，按照指定的目录结构整理欣赏歌曲文件，确认完成后再关闭本弹窗)
      </div>
      <div style="color: red">
      以下文件不可上传，请注意文件所在目录层级：
    </div>
    <div style="margin-left: 1em; line-height: 1.5">
      <div v-for="file in errorFiles" :key="file.webkitRelativePath">{{file.webkitRelativePath}}</div>
    </div>
    </div>
  </a-modal>
</template>

<script>
import { ref, reactive } from 'vue'
import { message, Modal } from 'ant-design-vue'
import { audioType, excelType } from '@/utils/dataMap/song'
import SparkMD5 from 'spark-md5'
import { uploadFileToOss } from '@/utils/oss'
import { xlsxImport } from '@/utils/fileUpload/xlsxImport'
import { createOfficialEnjoySong } from '@/services/songs'

export default {
  name: 'Import',
  setup () {
    const CONFIG_URL = 'https://prod-music-teach-file.oss-cn-shenzhen.aliyuncs.com/config-enjoy-song.xlsx'
    const browsers = ['Chrome', 'Firefox', 'Edge', 'QQ浏览器']
    const secondary = ['mp3', 'image', 'config.xlsx', 'config.xls']
    const ignoreFiles = ['.DS_Store']
    const SONG_NAME_INDEX = 2 // 歌曲识别码的位置
    const SONG_TYPE = 1 // 1 表示欣赏歌曲
    const allFileList = ref([])
    const errorFiles = ref([])
    const resources = ref([])
    const paraExcel = ref([])
    const pageState = reactive({
      errorVisible: false,
      canReset: true,
      uploading: false,
      md5: [],
      finish: false
    })
    const mp3FileConfig = []
    let xlsxArr = []

    const formatXlsxData = (arr) => {
      // 所属教材ID: 65
      // 歌曲中文名: "大海啊，故乡测试0520"
      // 歌曲简拼: "DHAGX"
      // 歌曲识别码: 1000007
      return arr.map(item => {
        return {
          book_id: item.所属教材ID,
          song_name: item.歌曲中文名,
          py: item.歌曲简拼,
          song_code: item.歌曲识别码
        }
      })
    }

    const formatMp3ConfigData = (arr) => {
      return arr.map(item => {
        return {
          name: item.名字,
          start_time: item.开始时间,
          color: item.颜色
        }
      })
    }

    const showRemoveConfirm = file => {
      Modal.confirm({
        title: '确定删除',
        content: <div>当前文件已上传：{file.percent}%，确认删除？</div>,
        onOk () {
          allFileList.value = allFileList.value.filter(item => item.uid !== file.uid)
        }
      })
    }

    const handleRemove = file => {
      if (file.name.includes('config')) {
        message.error('不可删除配置文件！')
        return
      }
      if (file.status && file.status !== 'error') {
        showRemoveConfirm(file)
        return
      }
      allFileList.value = allFileList.value.filter(item => item.uid !== file.uid)
    }

    const beforeUpload = (file, fileList) => {
      if (ignoreFiles.includes(file.name)) {
        errorFiles.value.push(file)
      }
      // batch-import/mp3/歌曲识别码/歌曲/歌曲.mp3 OR 歌曲.xlsx
      // batch-import/image/歌曲识别码/图片.jpg
      const filePath = file.webkitRelativePath.split('/')
      if (
        !secondary.includes(filePath[1]) || // 检查二级目录和文件名
        (filePath.length > 2 && Number.isNaN(Number(filePath[2]))) // 检查歌曲识别码
      ) {
        errorFiles.value.push(file)
      }
      // 只在最后一个文件修改 state，减少更新次数
      if (fileList.indexOf(file) === fileList.length - 1) {
        if (errorFiles.value.length > 0) {
          errorFiles.value = errorFiles.value.slice()
          pageState.errorVisible = true
        }
        allFileList.value = fileList.filter(i => !ignoreFiles.includes(i.name))
      }
      return false
    }

    const handleErrorsCancel = () => {
      pageState.errorVisible = false
      errorFiles.value = []
    }

    const handleReset = () => {
      allFileList.value = []
    }

    const complexUpload = async index => {
      const split = allFileList.value[index].name.split('.')
      const suffix = split[split.length - 1]

      if (audioType.findIndex(currType => currType === suffix) > -1) {
        // 只有歌曲文件需要 md5
        await uploadWithMd5(index)
      } else {
        await simpleUploadOne(index)
      }
      allFileList.value = allFileList.value.slice()

      if (index + 1 >= allFileList.value.length) {
        pageState.finish = true
        pageState.uploading = false
        message.success('文件已上传，请点击“创建”按钮保存')
        return
      }

      return complexUpload(index + 1)
    }

    const handleUpload = () => {
      const xls = allFileList.value.filter(item => item.name.includes('config'))

      if (xls.length < 1) {
        message.info('缺少 config 文件，请根据说明准备文件夹')
        return false
      }
      if (xls.length > 1) {
        message.info('请勿上传多个 config 文件')
        return false
      }

      pageState.canReset = false
      pageState.uploading = true

      complexUpload(0)
    }

    const FILE_NAME_SEP = '-'

    const cutFilename = filename => {
      // 将文件名以 FILE_NAME_SEP 分割，去掉第一个占位符
      const splits = filename.split(FILE_NAME_SEP)
      const indexNum = Number(splits.shift())
      // 没有序号/序号不合法的音频，直接返回文件名
      if (indexNum.toString() === 'NaN' || indexNum === 0 || splits.length === 0) {
        return filename
      }

      return splits.join(FILE_NAME_SEP) // 将剩下的粘回去
    }

    const uploadWithMd5 = async index => {
      const file = allFileList.value[index]

      // 跳过已经上传的文件
      if (file.status === 'done') return

      const blobSlice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice
      const chunkSize = 2097152 // Read in chunks of 2MB
      const chunks = Math.ceil(file.size / chunkSize)
      const spark = new SparkMD5.ArrayBuffer()
      const reader = new FileReader()
      let currentChunk = 0

      function loadNext () {
        const start = currentChunk * chunkSize

        const end =
          start + chunkSize >= file.size ? file.size : start + chunkSize

        reader.readAsArrayBuffer(blobSlice.call(file, start, end))
      }

      reader.onload = e => {
        spark.append(e.target.result) // Append array buffer
        currentChunk += 1
        if (currentChunk < chunks) {
          loadNext()
        } else {
          pageState.md5.push([file.webkitRelativePath, spark.end()])
        }
      }

      loadNext()

      try {
        const tmpName = file.name
        const storeName = cutFilename(tmpName)
        const data = await uploadFileToOss(file, true, null, storeName)

        file.percent = 100
        file.status = 'done'
        file.url = data.url
        file.md5 = data.md5

        allFileList.value = allFileList.value.slice()

        const splits = file.webkitRelativePath.split('/')
        const fileName = file.name.split('.').shift()
        mp3FileConfig[splits[2]] = mp3FileConfig[splits[2]] ?? []
        const has = mp3FileConfig[splits[2]].find(i => i.song_name === fileName)
        if (has) {
          mp3FileConfig[splits[2]].map(i => {
            if (i.song_name === fileName) {
              i.mp3_file_url = file.url
              i.mp3_file_md5 = file.md5
              return i
            }
            return i
          })
        } else {
          mp3FileConfig[splits[2]].push({
            song_name: fileName,
            mp3_file_url: file.url,
            mp3_file_md5: file.md5,
            para_info: []
          })
        }

        // 文件上传结束，下面将上传后的文件处理到 resources 数组里
        const mp3_file_md5 = pageState.md5.find(
          item => item[0] === file.webkitRelativePath
        )[1]
        // 以文件所在的文件夹作为 dir 标记，不要携带文件名
        splits.pop() // 去除文件名
        const mp3File = {
          dir: splits.join('/'),
          filename: file.name, // 记下文件名，后面会通过这个来对 mp3 排序
          mp3_file_url: file.url,
          mp3_file_md5,
          para_excel_url: undefined
        }

        const song_name = file.webkitRelativePath.split('/')[SONG_NAME_INDEX]
        const resourceIndex = resources.value.findIndex(
          item => String(item.song_name) === String(song_name)
        )
        if (resourceIndex > -1) {
          // 该歌曲已存在
          resources.value[resourceIndex].mp3_file_url.push(mp3File)
        } else {
          // 该歌曲不存在
          const item = {
            song_name,
            song_type: 1,
            mp3_file_url: [mp3File],
            picture_url: []
          }
          resources.value.push(item)
        }
      } catch (e) {
        message.error('上传出错')
        console.error(e)
      }
    }

    const simpleUploadOne = async index => {
      const file = allFileList.value[index]

      // 跳过已经上传的文件
      if (file.status === 'done') return

      const reader = new FileReader()
      reader.readAsDataURL(file)

      try {
        const data = await uploadFileToOss(file, true)

        file.percent = 100
        file.status = 'done'
        file.url = data.url
        file.md5 = data.md5

        allFileList.value = allFileList.value.slice()

        // 文件上传结束，下面将上传后的文件处理到 resources/paraExcel 数组里
        const suffix = file.name.split('.').pop()
        const fileName = file.name.split('.').shift()
        if (excelType.find(excel => excel === suffix) !== undefined) {
          // 读取xlsx
          const { Sheet1 } = await xlsxImport(file)
          const splits = file.webkitRelativePath.split('/')
          if (file.name.includes('config.')) {
            xlsxArr = formatXlsxData(Sheet1)

            // 上传的是配置文件，将配置文件保存到单独的数组里

            splits.pop() // 去除文件名
            if (splits.length > 1) {
              // 以文件所在的文件夹作为标记，不要携带文件名
              paraExcel.value.push([splits.join('/'), file])
            }
          } else {
            // 获取分段配置文件数据
            if (file.name.includes('.xlsx') || file.name.includes('.xls')) {
              const para_info = formatMp3ConfigData(Sheet1)
              mp3FileConfig[splits[2]] = mp3FileConfig[splits[2]] ?? []
              const has = mp3FileConfig[splits[2]].find(i => i.song_name === fileName)
              if (has) {
                mp3FileConfig[splits[2]].map(i => {
                  if (i.song_name === fileName) {
                    i.para_info = para_info
                    return i
                  }
                  return i
                })
              } else {
                mp3FileConfig[splits[2]].push({
                  song_name: fileName,
                  mp3_file_url: '',
                  mp3_file_md5: '',
                  para_info
                })
              }
            }
          }
        } else {
          // 上传的是图片，取出歌曲识别码
          const song_name = file.webkitRelativePath.split('/')[SONG_NAME_INDEX]
          const resourceIndex = resources.value.findIndex(
            item => String(item.song_name) === String(song_name)
          )
          if (resourceIndex > -1) {
            // 该歌曲已存在
            resources.value[resourceIndex].picture_url.push(file.url)
          } else {
            // 该歌曲不存在，新建一个歌曲
            const item = {
              song_name,
              song_type: SONG_TYPE,
              mp3_file_url: [],
              picture_url: [file.url]
            }
            resources.value.push(item)
          }
        }
      } catch (error) {
        message.error('上传出错')
        console.error(error)
      }
    }

    const handleBatch = async () => {
      pageState.loading = true
      const configFile = allFileList.value.find(item => item.name.includes('config'))
      const config_file_url = configFile.url

      resources.value.forEach(resource => {
        // 处理排序问题
        // resource.mp3_file_url = resource.mp3_file_url.sort(
        //   (item1, item2) =>
        //     Number.parseInt(item1.filename) - Number.parseInt(item2.filename, 10)
        // )
        // resource.mp3_file_url.forEach(item => {
        //   const temp = paraExcel.value.find(
        //     currParaExcel => currParaExcel[0] === item.dir
        //   )
        //   item.para_excel_url = temp ? temp[1].url : undefined
        //   item.dir = undefined
        //   item.filename = undefined
        // })
      })

      for (const k in resources.value) {
        const params = {}
        resources.value[k].song_code = parseInt(resources.value[k].song_name)
        const otherData = xlsxArr.filter(i => i.song_code === resources.value[k].song_code)[0]
        params.py = otherData.py
        params.name = otherData.song_name
        params.book_id = otherData.book_id || 0
        params.song_code = parseInt(resources.value[k].song_name)
        params.picture_url = resources.value[k].picture_url || []
        if (mp3FileConfig[params.song_code]) {
          params.mp3_file_config = mp3FileConfig[params.song_code].filter(i => i.mp3_file_url).sort(
            (item1, item2) =>
              Number.parseInt(item1.song_name) - Number.parseInt(item2.song_name, 10)
          ).map(i => {
            i.song_name = cutFilename(i.song_name)
            return i
          })
        } else {
          params.mp3_file_config = []
        }
        await createOfficialEnjoySong(params)
      }

      pageState.loading = false
      message.success('批量上传成功')
      history.go(-1)
    }

    return {
      errorFiles,
      pageState,
      CONFIG_URL,
      browsers,
      history,
      allFileList,
      handleRemove,
      beforeUpload,
      handleErrorsCancel,
      handleReset,
      handleUpload,
      handleBatch
    }
  }
}
</script>

<style lang="less" scoped>
.outerLevel {
  padding-left: 2em;
}
.innerLevel{
  padding-left: 1.5em
}
</style>
